import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Calendar, Download, Mail } from 'app/components/Icons'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  label: string
  file?: string
  icon?: Icon
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  label = 'Button',
  file,
  icon,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'default',
}: Props) {
  let iconDom

  switch (icon) {
    case 'calendar':
      iconDom = <Calendar />
      break
    case 'mail':
      iconDom = <Mail />
      break
    case 'download':
      iconDom = <Download />
      break
  }

  const buttonClassName = `${className ? className : ''}`
  const buttonDOM = (
    <>
      {icon ? iconDom : null}
      <Label>{label}</Label>
    </>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {buttonDOM}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            onClick != undefined ? (
              <Static
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {buttonDOM}
              </Static>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {buttonDOM}
              </Static>
            ) : target === 'tel' || target === 'mailto' ? (
              <Anchor
                className={buttonClassName}
                href={`${target}:${URL}`}
                rel="noopener"
                variant={variant}
              >
                {buttonDOM}
              </Anchor>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {buttonDOM}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {buttonDOM}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const Style = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  background: ${theme.colors.variants.secondaryLight};
  color: ${theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 1.3125rem;
  padding: 0 1.875rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${theme.colors.variants.secondaryDark};
  }

  svg {
    width: auto;
    height: 1.125rem;
    fill: none;
    margin-right: 0.75rem;
    stroke: ${theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }
`

const PrimaryStyle = css`
  ${Style}

  background: ${theme.colors.variants.primaryLight};
  &:hover {
    background: ${theme.colors.variants.primaryDark};
  }
`

const OutlineStyle = css`
  ${Style}

  background: none;
  border: 0.0625rem solid ${theme.colors.variants.secondaryDark};
  color: ${theme.colors.variants.secondaryDark};
  &:hover {
    background: ${theme.colors.variants.secondaryDark};
    border-color: transparent;
    color: ${theme.colors.variants.neutralLight4};

    svg {
      fill: ${theme.colors.variants.neutralLight4};
    }
  }

  svg {
    fill: ${theme.colors.variants.secondaryLight};
    stroke: none;
    transition: 0.3s ease-in-out;
  }
`

const InvertStyle = css`
  ${Style}

  background: none;
  border: 0.0625rem solid ${theme.colors.variants.neutralLight4};
  &:hover {
    background: ${theme.colors.variants.primaryLight};
    border-color: transparent;
  }
`

const SimpleStyle = css`
  display: inline-block;
  color: ${theme.colors.variants.primaryLight};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 1.3125rem;
  padding-bottom: 0.375rem;
  position: relative;
  text-transform: uppercase;
  &:hover {
    &:before {
      right: 50%;
    }
    &:after {
      left: 50%;
    }
  }
  &:before,
  &:after {
    content: '';
    height: 0.125rem;
    background: ${theme.colors.variants.secondaryLight};
    position: absolute;
    bottom: 0;
    transition: 0.3s ease-in-out;
  }
  &:before {
    right: 100%;
    left: 0;
  }
  &:after {
    right: 0;
    left: 100%;
  }
`

const Anchor = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'primary':
        return PrimaryStyle
      case 'outline':
        return OutlineStyle
      case 'invert':
        return InvertStyle
      case 'simple':
        return SimpleStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'primary':
        return PrimaryStyle
      case 'outline':
        return OutlineStyle
      case 'invert':
        return InvertStyle
      case 'simple':
        return SimpleStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return Style
      case 'primary':
        return PrimaryStyle
      case 'outline':
        return OutlineStyle
      case 'invert':
        return InvertStyle
      case 'simple':
        return SimpleStyle
    }
  }}
`

const Label = styled.span``

interface ContainerProps {
  icon?: Icon
  variant: Variant
}

export type Variant = 'default' | 'primary' | 'outline' | 'invert' | 'simple'

export type Icon = 'calendar' | 'mail' | 'download'
